import React, { Dispatch, FC, memo, SetStateAction, useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { EventBookType } from '../../../core/models/events';
import { BookEventInfoItem } from './eventInfo';
import { BookCheckInfoItem } from './checkInfo';
import { TicketsSelector } from './ticketsSelector';
import { useCheckoutHelpers } from '../../../helpers/customHooks';
import { DistributionType } from '../../../store/distribution/types';
import { OrderSkeleton } from '../../../components/skeletons/orderSkeleton';
import { SelectedTicketGroupType, TicketGroupTEType } from '../book';

const useStyles = makeStyles()((theme) => ({
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(180.62deg, rgba(28, 28, 28, 0) -91.79%, rgba(18, 17, 17, 0.41) 157.66%)',
    boxShadow: '4px -4px 16px rgb(0 0 0 / 63%), -4px 4px 16px rgba(0, 0, 0, 0.63)',
    borderRadius: '12px',
    padding: '32px 16px',
    minHeight: '520px',
    [theme.breakpoints.down(425)]: {
      padding: '24px 8px',
    },
  },
  container: {
    width: '343px',
  },
  header: {
    padding: '0px 16px 24px 16px',
  },
  content: {
    padding: '0px 16px',
  },
  title: {
    paddingBottom: '16px',
  },
  subTitle: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.main,
    paddingBottom: '8px',
  },
  divider: {
    borderColor: '#A8A8A8',
  },
  footer: {
    padding: '0 0 16px 0',
  },
  totalPrice: {
    color: theme.palette.secondary.main,
    marginRight: '0.25em',
  },
  totalPriceContainer: {
    paddingTop: '16px',
  },
  titleFees: {
    fontSize: '0.75rem',
    color: '#A8A8A8',
  },
}));

export const Order: FC<OrderType> = memo(
  ({ event, ticketGroup, handleTicketsCount, totalPrice, distribution, setSelectedTicketGroups }) => {
    const { t } = useTranslation();
    const { getDistributionInfo } = useCheckoutHelpers();
    const { classes } = useStyles();

    const [title, setTitle] = useState<string>(null);

    const distributionInfo = getDistributionInfo(null, distribution);
    const distributor = t(distributionInfo, { distributor: distribution.distributorSlug });

    const isLoading = !event && !ticketGroup;

    useEffect(
      () => () => {
        setSelectedTicketGroups([]);
      },
      []
    );

    return (
      <Grid container className={classes.paperContainer}>
        {isLoading && <OrderSkeleton />}
        {!isLoading && (
          <Grid item className={classes.container}>
            <Grid className={classes.header} container item direction={'column'} justifyContent={'start'}>
              <Typography component={'div'} className={classes.title}>
                This is your order
              </Typography>
              <Grid container item className={classes.footer} direction={'column'} justifyContent={'start'}>
                <BookCheckInfoItem isOk title={`Section ${ticketGroup?.tevo_section_name}, Row ${ticketGroup?.row}`} />
                {/* <BookCheckInfoItem isOk title={'Possibly Obstructed'} /> */}
              </Grid>
              <Typography component={'div'} className={classes.subTitle}>
                Select number of tickets:
              </Typography>
              <div style={{ paddingBottom: '16px' }}>
                <TicketsSelector
                  ticketGroup={ticketGroup}
                  handleTicketsCount={handleTicketsCount}
                  setTitle={setTitle}
                />
              </div>
              <BookCheckInfoItem isOk={!!totalPrice} title={title} />
              {totalPrice && (
                <Grid item className={classes.totalPriceContainer}>
                  <Typography component={'div'} className={classes.totalPrice}>
                    Total price:
                  </Typography>
                  <Typography component={'span'}>{totalPrice}</Typography>
                </Grid>
              )}
            </Grid>
            <Divider className={classes.divider} />
            <Grid className={classes.content} container item direction={'column'} justifyContent={'start'}>
              <BookEventInfoItem
                title={'Electronic tickets'}
                subTitle={'These are e-tickets and will be delivered to your email address.'}
                icon={'mail'}
              />
              <BookEventInfoItem
                title={event?.venue?.title}
                subTitle={`${event?.venue?.address}, ${event?.venue?.city}`}
                icon={'location'}
              />
              <BookEventInfoItem
                title={`${dayjs(event?.eventDate).format('M/D/YYYY')}, ${dayjs(event?.eventDate).format('LT')}`}
                icon={'calendar'}
              />
            </Grid>
            <Divider className={classes.divider} />
            {/* {event?.showFees && <Fees fees={event?.fees} />} */}
            {/* {totalPrice && ( */}
            {/*  <Grid item className={classes.totalPriceContainer}> */}
            {/*    <Typography component={'div'} className={classes.totalPrice}> */}
            {/*      Total price (fees are included): */}
            {/*    </Typography> */}
            {/*    <Typography component={'span'}>{totalPrice}</Typography> */}
            {/*  </Grid> */}
            {/* )} */}
            {distributionInfo && (
              <Grid container flexDirection={'column'} pl={'16px'} pt={'10px'}>
                <BookCheckInfoItem isOk title={distributor} />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

type OrderType = {
  ticketGroup: TicketGroupTEType;
  handleTicketsCount: (count: number, ticketGroup: TicketGroupTEType) => void;
  totalPrice: string;
  distribution: DistributionType;
  event: EventBookType;
  setSelectedTicketGroups: Dispatch<SetStateAction<SelectedTicketGroupType[]>>;
};
